@import "styles/variables.scss";

.help-container {
  .account-items {
    list-style: none;
    padding: 0;

    li {
      margin: 10px 0px;
      display: flex;
      border: 1px solid $main-color;
      cursor: pointer;
      font-size: 18px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .item-name {
        padding: 10px;
        width: calc(100% - 17px);
      }
    }
  }

  .account-overflow {
    padding: 10px;

    @media (min-width: $mobile-screen-min) {
      padding: 0 40px 40px;
    }

    .form-group {
      margin-bottom: 15px;
      letter-spacing: 0.8px;
      font-size: 16px;
    }

    textarea {
      height: 30vh;
    }

    .form-control {
      -webkit-appearance: none;

      &::-webkit-input-placeholder {
        text-transform: uppercase;
      }
      &:-moz-placeholder {
        text-transform: uppercase;
      }
      &::-moz-placeholder {
        text-transform: uppercase;
      }
      &:-ms-input-placeholder {
        text-transform: uppercase;
      }
      &::placeholder {
        text-transform: uppercase;
      }
    }

    .btn {
      width: 100%;
      background-color: $main-color;
      text-transform: uppercase;
      letter-spacing: 0.9px;
      font-size: 18px;
      color: white;
      border: none;
      padding: 8px 0px;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    width: 100%;
    border: 1px solid $main-color;
    padding: 2px 10px;
    color: $primary-text-color;
    font-family: "FuturaBT-Light";

    &:before,
    &:after {
      display: none;
    }
  }
  .MuiButtonBase-root {
    outline: none;
  }

  .email-sent-info {
    color: $main-color;
    margin-top: 20px;
    font-weight: bold;

    @media (min-width: $mobile-screen-min) {
      font-size: 15px;
    }
  }

  .block-off-date-form {
    textarea {
      height: 20vh;
    }

    .time_picker_container {
      border: 1px solid $main-color;

      .time_picker_preview {
        box-shadow: none;
        height: 38px;

        .preview_container {
          height: auto;
          line-height: 100%;
          transform: none;
          position: relative;
          left: 0;
          font-size: 16px;
          padding: 0.675rem 0.75rem;
          letter-spacing: normal;
          color: $primary-text-color;
          font-family: $font-light;
        }
      }
    }

    .modal_container {
      max-width: 400px;
      .picker_container {
        margin: 0 auto;
      }
    }

    .btn {
      margin: 10px 0;
    }
  }
}
