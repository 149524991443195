@import "../../../styles/variables.scss";

.edit-profile {
  &__heading {
    display: none;
    font-size: 23px;
    font-family: $font-light;

    @media #{$grid-lg} {
      display: block;
    }
  }

  .bank-data-changed {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#000, 0.2);
    z-index: 9000;
    display: flex;
    align-items: center;

    &.hidden {
      display: none !important;
    }

    .bank-data-changed-content {
      position: fixed;
      top: 50%;
      left: 50%;
      width: 600px;
      max-width: 100%;
      background-color: white;
      transform: translate(-50%, -50%);
      padding: 30px 20px;
      font-size: 16px;
      text-align: center;

      .bank-data-changed-image {
        margin-bottom: 30px;
      }

      @media #{$grid-lg} {
        font-size: 18px;
      }

      .btn {
        width: 200px;
        margin: 50px auto 0;
      }
    }
  }
}
