@import 'variables.scss';
@import 'icons.scss';
@import 'fonts.scss';

body {
  font-family: 'FuturaLTPro-Book', 'Segoe UI', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
}

input.form-control {
  border-radius: 0;

  &:focus {
    box-shadow: none;
    border-color: #3c8dbc;
  }
}

.only-desktop {
  @media (max-width: $mobile-screen-max) {
    display: none;
  }
}

.font-light {
  font-family: $font-light;
}

.form-control {
  border-radius: 0;
  border-color: $main-color;
  font-size: 16px;
  color: $primary-text-color;
  font-family: $font-light;

  &.form-control-lg {
    height: 65px;
  }

  &.input-password {
      padding-right: 2.5rem;
  }

  &:focus {
    box-shadow: none;
    border-color: $main-color;
  }
}

.btn {
  border-radius: 0;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.input-group-icon {
  background-color: white;
  padding: 0.375rem 0.55rem;

  .input-icon {
    max-height: 20px;
    max-width: 20px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.invalid-field {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #f86c6b;
}

.custom-checkbox {
  .custom-control-label::before {
    border-radius: 0;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
}

a {
  color: #3c8dbc;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: #72afd2;
  }
}

.round-separator {
  position: relative;

  .circle {
    background-color: $main-color;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 0 auto;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: calc(50% + 10px);
      top: 2.5px;
      height: 1px;
      background-color: $main-color;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      left: calc(50% + 10px);
      top: 2.5px;
      height: 1px;
      background-color: $main-color;
    }
  }
}

.badge-primary {
  background-color: $accent-color-1;
}

.badge-secondary {
  background-color: white;
  border: 1px solid $primary-text-color;
  color: $primary-text-color;
}

.edit-profile-header {
  font-family: $font-light;
  padding-top: 10px;
  padding-bottom: 0px;
  color: $primary-text-color;
  align-items: center;
  font-size: 15px;
  display: flex;
  justify-content: center;

  &__title:not(.property) {
    // display: none;
    &::before {
      content: ':';
      position: relative;
      left: 100%;

      @media #{$grid-lg} {
        display: none;
      }
    }
    @media #{$grid-lg} {
      display: block;
    }
  }

  &__title.property {
    display: block;

    &::before {
      content: ':';
      position: relative;
      left: 100%;

      @media #{$grid-lg} {
        display: none;
      }
    }
  }

  @media #{$grid-lg} {
    font-size: 60px;
    justify-content: space-between;
    border-bottom: 1px solid $main-color;
    padding-top: 0;
    padding-bottom: 20px;
  }

  &__property-name {
    font-size: 15px;
    color: $main-color;
    font-family: $font-bold;
    margin-left: 10px;

    @media #{$grid-lg} {
      font-size: 30px;
      margin-left: 0;
    }
  }
}

.swal-modal {
  .swal-text {
    font-size: 18px;
    text-align: center;
  }

  .swal-footer {
    text-align: center;

    .swal-button {
      text-transform: uppercase;
      border-radius: 0;
    }

    .swal-button--danger {
      width: 200px;
      background-color: $main-color;
      border: 1px solid $main-color;

      &:hover {
        background-color: rgba($color: $main-color, $alpha: 0.8);
      }
    }

    .swal-button--cancel {
      width: 180px;
      background-color: transparent;
      color: $main-color;
      border: 1px solid $main-color;
    }
  }
}

.rtl {
  direction: rtl;
}

.upload-button {
  padding: 4px;

  &:active {
    transform: translateY(2px);
  }
}

.file-input-button {
  background-color: transparent;
  color: $main-color;
  border: 1px solid $main-color;
  width: 100%;
  cursor: pointer;
  outline: none;

  &:active {
    outline: none;
    transform: translateY(2px);
  }
}

.clicked {
  animation: buttonClickAnimation 0.3s linear;
}

@keyframes buttonClickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes errorAnimation {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
}
