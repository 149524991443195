$modal-width: 25%;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999 !important;
}

.custom-modal {
  position: relative;
  left: calc((100% - 25%) / 2);
  border-radius: 10px;
  padding: 65px 20px;
  width: $modal-width;
  height: auto;
  animation: slideIn 0.3s ease-out;
  z-index: 100000 !important;

  @media (max-width: 1300px) {
    width: 40%;
    left: calc((100% - 40%) / 2);
  }

  @media (max-width: 900px) {
    width: 60%;
    left: calc((100% - 60%) / 2);
  }
  @media (max-width: 550px) {
    width: 100%;
    left: 0;
  }
}

.modal-content {
  padding: 10px 30px;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;

  &:active {
    transform: translateY(1px);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
