@import '../../../styles/variables.scss';

.header {
  display: flex;
  border-bottom: 1px solid $main-color;
  padding: 0 0 10px 0;
  align-items: flex-start;

  @media #{$grid-md} {
    padding: 0 0 30px 0;
  }
}

.nickname {
  text-transform: uppercase;
  margin-left: 30px;
  font-size: 29px;

  @media #{$grid-md} {
    font-size: 34px;
  }
}

.info {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  font-family: 'FuturaBT-Light';
  margin: 20px 0 0;

  @media #{$grid-md} {
    font-size: 23px;
    line-height: 36px;
    margin: 35px 0 0;
  }

  a {
    color: $main-color;
    font-family: 'FuturaLTPro-Book';
  }
}

.utilitiesDetailsWrapper {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.section {
  margin-top: 25px;

  @media #{$grid-md} {
    margin-top: 45px;
  }
}

.sectionHeader {
  color: $main-color;
  font-family: 'FuturaBT-Light';
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 20px;

  @media #{$grid-md} {
    font-size: 28px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 15px;

  .col {
    background-color: #ad8c6310;
    border-radius: 8px;
    border: 1px solid #ad8c63;

    h3 {
      padding: 8px;
      border-bottom: 1px solid #ad8c63;
      margin-bottom: 8px;
    }

    div {
      padding: 8px;
    }
  }

  @media #{$grid-md} {
    flex-wrap: nowrap;
    margin-top: 20px;
  }
}

.col {
  @media #{$grid-md} {
    margin-top: 0;

    &.col40 {
      width: 40%;
    }
  }

  &.mobileSingle:nth-of-type(1n + 2) {
    @media (max-width: #{map-get($breakpoints, 'md')}) {
      margin-top: 20px;
      width: 100%;
    }
  }

  &.fill {
    width: 100%;
  }

  h3 {
    font-size: 17px;
    font-family: 'FuturaBT-Light';
    margin-bottom: 0;
  }

  span {
    font-family: 'FuturaLTPro-Book';
    font-size: 18px;
    color: rgba(35, 40, 44, 0.867);

    @media #{$grid-md} {
      font-size: 23px;
    }
  }
}

.bankButton {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: '520px') {
    margin-bottom: unset;
  }
}

.bankButtonContainer {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;

  @media (max-width: '520px') {
    flex-wrap: wrap;
    gap: unset;
  }
}

.upload-doc-btn {
  width: 100%;
  background-color: $main-color;
  text-transform: uppercase;
  letter-spacing: 0.9px;
  font-size: 18px;
  color: white;
  border: none;
  padding: 8px 0px;
  margin-top: 5px;

  &:hover {
    color: white;
  }

  &:active {
    transform: translateY(2px);
  }
}

.doc-details-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 3.5rem !important;
}

.download-link {
  font-size: 14px;
  color: #ad8c63dd;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #ad8c63dd;
  width: max-content;

  &:hover {
    text-decoration: none;
  }
  &:active {
    transform: translateY(2px);
  }
}

.expiry-date {
  list-style: none;
  font-size: 16px;
  color: #23282cdd;
}

.expiry-date-value {
  margin: 0;
  font-size: 14px;
  color: #ad8c63dd;
  font-weight: bold;
  word-wrap: break-word;
}

.note-title {
  list-style: none;
  font-size: 16px;
  color: #23282cdd;
}

.note-value {
  margin: 0;
  font-size: 14px;
  color: #ad8c63dd;
  font-weight: bold;
}

.footer-buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.document-footer-btn {
  padding: 5px;
  border-radius: 0.4rem;
  width: 48%;

  span {
    font-size: 14px !important;
    letter-spacing: 0.03em;
    display: inline-block;
    color: unset;
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1900ms infinite;
  animation: ellipsis steps(4, end) 1900ms infinite;
  content: '\2026';
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}
