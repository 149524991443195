@import "../../../styles/variables.scss";

.textbox.form-group {
  font-family: $font-light;

  label {
    font-family: $font-light;
    margin-bottom: 0.3rem;
    font-size: $form-mobile-font-size-header;

    @media #{$grid-md} {
      font-size: $form-desktop-font-size-header;
      margin-bottom: 0.5rem;
    }
  }

  .form-control {
    height: auto;
    font-size: $form-mobile-font-size;

    @media #{$grid-md} {
      font-size: $form-desktop-font-size;
    }
  }
}
