@import '../../../styles/variables.scss';

.radio.form-group {
  font-family: $font-bold;

  label {
    font-family: $font-bold;
    font-size: $form-mobile-font-size;
  }

  .form-check {
    margin: 5px 0;
    display: flex;
    padding-left: 1.6rem;
    align-items: baseline;
  }

  input[type='radio'] {
    position: relative;

    & + label {
      padding-left: 20px;
      font-size: $form-mobile-font-size;
      font-family: $font-light !important;

      @media (max-width: 500px) {
        padding-left: 10px;
      }
    }

    &:disabled + label {
      color: $gray-color;
      cursor: not-allowed;

      &::before {
        border-color: $gray-color;
      }
    }
    &:disabled::before {
      border-color: $gray-color;
    }

    &::before {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 50%;
      left: 50%;
      border: 1px solid black;
      background-color: white;
      border-radius: 50%;
      content: '';
      transform: translate(-50%, -50%);
    }

    &:checked::after {
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      background-color: $main-color;
      content: '';
      display: inline-block;
      transform: translate(-50%, -50%);
    }
  }
}
