@font-face {
  font-family: "FuturaBT-Light";
  src: url("./../assets/fonts/FuturaBT-Light.eot?#iefix") format("embedded-opentype"),
    url("./../assets/fonts/FuturaBT-Light.woff") format("woff"),
    url("./../assets/fonts/FuturaBT-Light.ttf") format("truetype"),
    url("./../assets/fonts/FuturaBT-Light.svg#FuturaBT-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FuturaLTPro-Book";
  src: url("./../assets/fonts/FuturaLTPro-Book.eot?#iefix") format("embedded-opentype"),
    url("./../assets/fonts/FuturaLTPro-Book.otf") format("opentype"),
    url("./../assets/fonts/FuturaLTPro-Book.woff") format("woff"),
    url("./../assets/fonts/FuturaLTPro-Book.ttf") format("truetype"),
    url("./../assets/fonts/FuturaLTPro-Book.svg#FuturaLTPro-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}
