@import "styles/variables.scss";

.mail-for-support {
  padding: 10px;

  @media (min-width: $mobile-screen-min) {
    padding: 0 40px 40px;
  }

  .form-group {
    margin-bottom: 15px;
    letter-spacing: 0.8px;
    font-size: 16px;
  }

  textarea {
    height: 30vh;
  }

  .form-control {
    &::placeholder {
      text-transform: uppercase;
    }
  }

  .btn {
    width: 100%;
    background-color: $main-color;
    text-transform: uppercase;
    letter-spacing: 0.9px;
    font-size: 18px;
    color: white;
    border: none;
    padding: 8px 0px;
  }

  .email-sent-info {
    color: $main-color;
    margin-top: 20px;
    font-weight: bold;

    @media (min-width: $mobile-screen-min) {
      font-size: 15px;
    }
  }
}
