@import '../../styles/variables.scss';

.edit-profile-form {
  .finshed-steps-summary {
    text-align: right;
    font-family: $font-bold;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;

    @media #{$grid-lg} {
      margin-top: -20px;
      margin-bottom: 40px;
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.5rem;

    @media #{$grid-md} {
      flex-wrap: nowrap;
    }

    > div {
      flex: 0 0 100%;

      @media #{$grid-md} {
        flex: 0 1 auto;
      }
    }

    .btn-edit {
      width: 100px;
      font-family: $font-light;
      font-size: 14px;
      color: $main-color;
      border: 1px solid $main-color;
      background-color: transparent;
      padding: 8px 15px;
      margin-bottom: 8px;

      @media #{$grid-sm} {
        width: 150px;
      }

      @media #{$grid-md} {
        padding: 15px;
        margin-top: 10px;
      }
    }
  }

  .hidden-section {
    display: none;
  }

  .section-status {
    text-transform: uppercase;
    color: $primary-text-color;
    font-family: $font-light;
    font-size: 13px;

    @media #{$grid-md} {
      font-size: 16px;
    }
  }

  .section-name {
    text-transform: uppercase;
    font-family: $font-bold;
    font-size: 18px;
    color: black;
    margin: 5px 0;

    @media #{$grid-md} {
      font-family: $font-light;
      font-size: 28px;
      color: $main-color;
      margin: 0 0 0.5rem 0;
    }
  }

  .section-description {
    font-size: 13px;
    font-family: $font-bold;
    color: $primary-text-color;

    @media #{$grid-md} {
      font-size: 18px;
      font-family: $font-light;
      color: black;
    }
  }

  .invalid-field {
    margin: 0 0 20px;
    font-size: 16px;
    font-family: $font-light;
  }

  .form-group {
    margin-top: 25px !important;
    margin-bottom: 5px !important;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    .btn {
      width: 100px;
      font-family: $font-light;
      font-size: 14px;

      @media #{$grid-sm} {
        width: 150px;
      }

      @media #{$grid-md} {
        width: 200px;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          width: 1px;
          height: 30px;
          top: 10px;
        }

        &::after {
          width: 0;
          height: 0;
          border-style: solid;
          content: '';
          position: absolute;
          top: 21px;
        }
      }

      &.btn-hidden {
        visibility: hidden;
      }
    }

    .btn-next {
      &.finish {
        padding: 8px 15px;

        @media #{$grid-md} {
          padding: 15px;
        }

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }

      @media #{$grid-md} {
        padding: 15px 60px 15px 0;

        &::before {
          background-color: white;
          right: 30%;
        }

        &::after {
          border-width: 5px 0 5px 10px;
          border-color: transparent transparent transparent white;
          right: 25px;
        }
      }
    }

    .btn-skip {
      margin-right: 15px;
      background-color: transparent;
      color: $main-color;
      border: 1px solid $main-color;

      &::before {
        background-color: $main-color;
      }

      &::after {
        border-width: 5px 0 5px 10px;
        border-color: transparent transparent transparent $main-color;
      }
    }

    .btn-prev {
      background-color: transparent;
      color: $main-color;
      border: 1px solid $main-color;

      @media #{$grid-md} {
        padding: 15px 0 15px 60px;

        &::before {
          background-color: $main-color;
          left: 30%;
        }

        &::after {
          border-width: 5px 10px 5px 0;
          border-color: transparent $main-color transparent transparent;
          left: 25px;
        }
      }
    }
  }

  .textbox-add-new {
    margin-top: 40px;
    font-size: $form-mobile-font-size-header;
    font-family: $font-light;
    text-decoration: underline;
    cursor: pointer;

    @media #{$grid-md} {
      font-size: $form-desktop-font-size-header;
    }
  }

  .bool-header {
    display: flex;
    font-size: $form-mobile-font-size-header;
    font-family: $font-light;

    @media #{$grid-md} {
      font-size: $form-desktop-font-size-header;
    }

    .bool-spacer {
      min-width: 250px;
      flex: 1 1;
      width: 20%;

      @media #{$grid-md} {
        min-width: 320px;
      }
    }

    .bool-label {
      padding-left: 1.25rem;

      @media #{$grid-md} {
        flex: 2;
      }
    }
  }
}

.error-message {
  color: red;
  font-size: 14px;
  margin: unset;
}

.upload-document {
  padding: 10px;

  @media (min-width: $mobile-screen-min) {
    padding: 0 10px 10px;
  }

  .form-group {
    margin-bottom: 15px;
    letter-spacing: 0.8px;
    font-size: 16px;
  }

  textarea {
    height: 30vh;
  }

  .form-control {
    &::placeholder {
      text-transform: uppercase;
    }
  }

  .email-sent-info {
    color: $main-color;
    margin-top: 20px;
    font-weight: bold;

    @media (min-width: $mobile-screen-min) {
      font-size: 15px;
    }
  }

  .nickname {
    text-transform: uppercase;
    margin-left: 30px;
    font-size: 29px;

    @media #{$grid-md} {
      font-size: 34px;
    }
  }

  .header {
    display: flex;
    border-bottom: 1px solid $main-color;
    padding: 0 0 10px 0;
    margin-bottom: 25px;
    align-items: flex-start;
  }
}

@mixin unopened-element {
  margin-top: 0.5rem;

  @media (max-width: 800px) {
    max-height: 0;
    overflow: hidden;
    transition: all 300ms cubic-bezier(0, 1, 0, 1);
  }
}

@mixin opened-element {
  margin-top: 0.5rem;

  @media (max-width: 800px) {
    height: auto;
    max-height: 9999px;
    overflow: hidden;
    transition: all 300ms cubic-bezier(1, 0, 1, 0);
  }
}

.utility-form-container {
  margin-top: 1rem;
  max-height: calc(100% - 150px);
  overflow: auto;
  padding: 0px 10px;

  @media (max-width: 1000px) {
    max-height: calc(100% - 100px);
  }

  @media (max-width: 400px) {
    padding: 0;
  }

  .form-card-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 16px;

    @media (max-width: 800px) {
      grid-template-columns: unset !important;
      grid-column: 1;
    }

    .add-utility-btn-wrapper {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      border: 1px solid rgb(173, 140, 99);
      background-color: rgba(173, 140, 99, 0.063);
    }
  }

  .form-footer-container {
    margin-top: 1rem;
    position: fixed;
    bottom: 16px;

    @media (max-width: 500px) {
      text-align: center;
      margin: 0 auto;
    }
  }
}

.form-card {
  border: 1px solid rgb(173, 140, 99);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgba(173, 140, 99, 0.063);
  position: relative;

  .remove-icon-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .form-card-title {
    border-bottom: 0.012em solid rgb(173, 140, 99);
    width: 98%;
    display: flex;
    align-items: center;
    margin-bottom: unset;
    padding-bottom: 0.8rem;

    p {
      margin-bottom: unset !important;
    }

    .expand-collapse {
      width: 1rem;
      text-align: center;
      margin-right: 0.5rem;
    }
  }

  .upload-btn {
    border: 1px solid rgb(173, 140, 99);
    border-radius: 0px;
  }
}

.utility-questions-wrapper {
  .question-plus-minus-wrapper {
    display: flex;
    width: 100%;

    @media (max-width: 800px) {
      cursor: pointer;
    }
  }

  .answer {
    @include unopened-element;
  }

  .answer.opened {
    @include opened-element;
  }
}

.utility-questions-wrapper.opened {
  @include opened-element;
}

.submit-utility-btn {
  padding: 8px 16px !important;

  @media (max-width: 500px) {
    width: 100% !important;
  }

  &:hover {
    background-color: rgba(173, 140, 99, 0.8) !important;
  }
}

.plusminus {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  font-weight: normal !important;
  color: #23282c;

  &.active {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }

  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #555d63;
    position: absolute;
    top: 50%;
    left: 0;
    transition: 0.35s;
    width: 100%;
    height: 0.12em;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
}
