
  .alert {
    background-color: black !important;
    border-radius: 0;
    color: white !important;
    font-size: 18px;
    margin: 0 -15px;
    margin-top: 20px;
    text-align: center;
    border: none;
  }