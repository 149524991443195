.new-password-content {
  .public-header {
    margin-bottom: 10px !important;
  }

  .input-icon-container{
    position: relative;
    width: 100%;
  }

  .new-password-desc {
    color: white;
    text-align: center;
    margin-bottom: 30px;
  }

  .alert {
    background-color: black !important;
    border-radius: 0;
    color: white !important;
    font-size: 18px;
    margin: 0 -15px;
    margin-top: 20px;
    text-align: center;
    border: none;
  }

  .password-eye-icon {
    font-size: 1rem;
    color: #ad8c63;
    cursor: pointer;
    z-index: 100;
  }

  .eye-icon-container {
    height: 100%;
    width: 50px;
    top: 0%;
    position: absolute;
    right: 0%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
