@import '../../../styles/variables.scss';

.checkbox.form-group {
  font-family: $font-light;

  .form-check {
    margin: 5px 0;
    display: flex;
  }

  label {
    font-family: $font-light;
    font-size: $form-mobile-font-size-header;

    @media #{$grid-md} {
      font-size: $form-desktop-font-size-header;
    }
  }

  input[type='checkbox'] {
    position: relative;
    margin-top: 5px;

    @media #{$grid-md} {
      margin-top: 0;
    }

    & + label {
      padding-left: 20px;
      font-size: $form-mobile-font-size;

      @media #{$grid-md} {
        font-size: $form-desktop-font-size;
      }
    }

    &::before {
      position: absolute;
      width: 18px;
      height: 18px;
      top: -1px;
      left: -1px;
      border: 1px solid black;
      background-color: white;
      content: '';

      @media #{$grid-md} {
        top: 9px;
      }
    }

    &:checked::after {
      position: absolute;
      content: '';
      display: block;
      top: 1px;
      left: 5px;
      display: block;
      width: 6px;
      height: 12px;
      border: solid $main-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @media #{$grid-md} {
        top: 10px;
      }
    }
  }
}
