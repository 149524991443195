@import "../../../styles/variables.scss";

.login-content {

    .password-eye-icon{
        font-size: 1.2rem;
        color: #AD8C63;
        cursor: pointer;
        z-index: 100;
    }
}
